import React from 'react';
// import { navigate } from 'gatsby';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import StorageIcon from '@material-ui/icons/Storage';
import HistoryIcon from '@material-ui/icons/History';
import WebIcon from '@material-ui/icons/Web';

// import SvgIcon from '@material-ui/core/SvgIcon';
// import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
// import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
// import RefreshIcon from '@material-ui/icons/Refresh';
// import LockIcon from '@material-ui/icons/Lock';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import PublicIcon from '@material-ui/icons/Public';

import Layout from '../components/layout';
import * as classes from './main.module.scss';

// import Logo from '../res/logo.svg';

const EXTENSION_STORE_URL = 'https://chrome.google.com/webstore/detail/tabmanagerio-tab-session/hjicnemefbakbanoigbaonlpgflhggdf';

function Main() {
  return (
    <>
      <Layout>

        <div className={classes.root}>
          {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: 600, margin: 200 }}>
              <h3>Sorry! This site is currently under construction. Please check back shortly!</h3>
            </div>
          </div> */}
          <section className={classes.top}>
            <Container maxWidth='lg'>
              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.lead}>
                    <h6>TABMANAGER.IO</h6>
                    <h1>Declutter your workspace and become more productive</h1>
                    <p>An all-in-one tab manager extension for managing your crowded browser windows.</p>
                    <Button className={classes.actionButton} variant="contained" size="large" color="primary">
                      <a href={EXTENSION_STORE_URL} target='_blank' rel='noreferrer'>Add Extension</a>
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.image}>
                    <img src='/images/lead-1.png' alt='crowded browser windows'/>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </section>


          <section className={classes.demo}>
            <Container maxWidth='lg'>
              <div className={classes.demoTitle}>
                <h2>Organized view of all your tabs</h2>
                <p className={classes.demoDescription}>Workspace overview, save tabs and windows, and easily restore your sessions.</p>
              </div>
              <div className={classes.productImage}>
                {/* <div className={classes.productImageTopbar}>
                  <div className={classes.productImageTopbarButtons}>
                    <div className={classes.productImageTopbarButtonRed}></div>
                    <div className={classes.productImageTopbarButtonYellow}></div>
                    <div className={classes.productImageTopbarButtonGreen}></div>
                  </div>
                </div>
                <div className={classes.productImageTopbarNav}>
                  <div className={classes.productImageTopbarNavButtons}>
                    <ArrowBackOutlinedIcon />
                    <ArrowForwardOutlinedIcon />
                    <RefreshIcon style={{ color: '#b8b8b8' }}/>
                  </div>
                  <div className={classes.productImageTopbarNavOmnibox}>
                    <LockIcon />
                    extension://tabmanager.io
                  </div>
                  <div className={classes.productImageTopbarNavRight}>
                    <SvgIcon style={{ fontSize: 12 }}>
                      <Logo />
                    </SvgIcon>
                    <MoreVertIcon style={{ fontSize: 18, color: 'gray' }}/>
                  </div>
                </div> */}
                <img src='/images/demo-2.png' alt='chrome google tab manager extension' />
              </div>

              <div className={classes.featureGrid}>
                <Grid container justify='center' spacing={4}>

                  <Grid item xs={12} sm={3}>
                    <div className={classes.featureGridItem}>
                      <div className={classes.featureGridItemIcon}>
                        <WebIcon />
                      </div>
                      <h4>Active Windows</h4>
                      <div>
                        <p>Overview of all active tabs and windows to quickly find what you are looking for.</p>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <div className={classes.featureGridItem}>
                      <div className={classes.featureGridItemIcon}>
                        <StorageIcon />
                      </div>
                      <h4>Save Windows</h4>
                      <div>
                        <p>Save windows that you want to keep around or share with others.</p>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <div className={classes.featureGridItem}>
                      <div className={classes.featureGridItemIcon}>
                        <HistoryIcon />
                      </div>
                      <h4>Sessions</h4>
                      <div>
                      <p>Keep backups of your active browsing sessions.</p>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <div className={classes.featureGridItem}>
                      <div className={classes.featureGridItemIcon}>
                        <PublicIcon />
                      </div>
                      <h4>Share Windows</h4>
                      <div>
                        <p>Create urls for your windows to share them with others.</p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

            </Container>
          </section>

          <section className={classes.summary}>
            <Container maxWidth='lg'>
              <Grid container justify="center" spacing={4}>

                <Grid item xs={12} sm={6}>
                  <div className={classes.summaryImage}>
                    <img src='/images/lead-2.png' alt='organized browser' />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className={classes.summaryTextContainer}>
                    <div className={classes.summaryTextContent}>
                      <h2>The best way to stay organized</h2>
                      <p>Take control of your tab hoarding, free up system resources and keep your projects organized.</p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </section>


        </div>
      </Layout>
    </>
  );
}

export default Main;
