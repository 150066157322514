// extracted by mini-css-extract-plugin
export const root = "main-module--root--19O1y";
export const top = "main-module--top--mTVqb";
export const lead = "main-module--lead--uoxYE";
export const actionButton = "main-module--action-button--24hAl";
export const image = "main-module--image--fM7D0";
export const demo = "main-module--demo--Y6qKQ";
export const demoTitle = "main-module--demo-title--3rT16";
export const demoDescription = "main-module--demo-description--1EuHi";
export const productImage = "main-module--product-image--2NAuu";
export const productImageTopbar = "main-module--product-image-topbar--23Ttq";
export const productImageTopbarButtons = "main-module--product-image-topbar-buttons--oEw5z";
export const productImageTopbarButtonRed = "main-module--product-image-topbar-button-red--1IQ3e";
export const productImageTopbarButtonYellow = "main-module--product-image-topbar-button-yellow--1ksbp";
export const productImageTopbarButtonGreen = "main-module--product-image-topbar-button-green--3R4sW";
export const productImageTopbarNav = "main-module--product-image-topbar-nav--1LFhD";
export const productImageTopbarNavButtons = "main-module--product-image-topbar-nav-buttons--2cRzJ";
export const productImageTopbarNavOmnibox = "main-module--product-image-topbar-nav-omnibox--2Tac-";
export const productImageTopbarNavRight = "main-module--product-image-topbar-nav-right--2B-VE";
export const featureGrid = "main-module--feature-grid--18VOy";
export const featureGridItem = "main-module--feature-grid-item--38n2O";
export const featureGridItemIcon = "main-module--feature-grid-item-icon--23qMn";
export const summary = "main-module--summary--NZyYY";
export const summaryImage = "main-module--summary-image--F8Rcs";
export const summaryTextContainer = "main-module--summary-text-container--2eSsS";
export const summaryTextContent = "main-module--summary-text-content--36XlU";